<template>
  <div class="pages__list">
    <div class="d-flex justify-space-between">
      <h1>Pages</h1>
      <v-btn @click="$router.push({ name: 'website.pages.create' })" color="primary" depressed
        ><v-icon left>mdi-plus</v-icon> Add Page</v-btn
      >
    </div>
    <v-card outlined>
      <Resource
        :document="document"
        :filter-fields="null"
        :filter-data="filterData"
        :filter-callback="setFilterData"
        :searchable="searchableField"
        :columns="columns"
        :update-router-callback="getUpdateRouterData"
        :routeName="updateRouteName"
      />
      <div class="d-flex justify-space-between mt-4 pl-4 pr-1">
        <limit-page :callback="setLimit" />
        <pagination
          :visible="0"
          v-if="!$parent.isLoading && document && document.length > 0"
          :page.sync="page"
        ></pagination>
      </div>
    </v-card>
  </div>
</template>
<script>
import Resource from '@/components/Resources';
import resourceListMixin from '@/mixins/resource-list';
import filter from '@/const/filters/product-filter';
export default {
  components: {
    Resource,
  },
  mixins: [resourceListMixin],
  data() {
    return {
      resourceType: 'pages',
      tabs: [
        { name: 'All', key: '' },
        { name: 'Visible', key: 'visible' },
        { name: 'Hidden', key: 'hidden' },
      ],
      columns: {
        // Selectable: {
        //   blankLabel: true,
        //   kind: 'selectable',
        //   width: 1,
        // },
        Title: {
          field: 'title',
          kind: 'view',
        },
        Created: {
          field: 'created_at',
          kind: 'datetime',
        },
      },
      updateRouteName: 'website.pages.update',
      searchableField: '',
      sortable: [],
      filter,
    };
  },
  created() {
    console.log(this.document);
  },
  methods: {},
};
</script>
